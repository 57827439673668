import React from 'react'
import Portfolios from '../components/portfolios'
import PortfoliosJSON from "../content/portfolios.json"

export default function cybersecurity() {
    const content = PortfoliosJSON.portfolios[2]
    return (
        <Portfolios content={content} subcontent={""} />
    )
}
